import React, { useState } from 'react';
import parseDate from '../utils/parseDate';


import { graphql } from "gatsby"
import "../theme/layout.scss"
import CustomFooter from '../components/CustomFooter';
import GdprCookies from '../components/GdprCookies';
import HeaderNew from '../components/HeaderNew';

import ModalSuscribe from '../components/layout/ModalSuscribre';


const TemasdelMomento = ({data }) =>{

    const TemasdelMomento = data.generico.edges
    // console.log(TemasdelMomento)
  //functions modal suscribe
  const [open, setOpen] = useState(false);
  function openSuscribe(){
    setOpen(true);
  }
  function closeSuscribe(){
    setOpen(false);
  }
    return (
      <>
        <HeaderNew openModal={openSuscribe}/>
        <div className='wrap-content'>
            <h2>Temas del momento</h2>

            {TemasdelMomento && TemasdelMomento.map(({ node }) => {
                const { titulo, resumen, slug, createdAt, autor} = node
                    return (
                        
                      <div className="item">
                          <h2 className="title"><b>{titulo}</b></h2>
                          <p className="resume-content">{resumen}</p>
                          <p className="date">{autor.nombre}</p>
                          <p className="date">{parseDate(createdAt)}</p>
                          <a href={`/mercados-a-la-apertura/${slug}`} >
                              <div className="link">Ver más</div>
                          </a>
                      </div>
                    )
               
            })}
        </div>
        <CustomFooter openModal={openSuscribe}/>
        <GdprCookies />
        {
          open && <ModalSuscribe closeModal={closeSuscribe}/>
        }
      </>
            
    )
}

export default TemasdelMomento

export const pageQuery = graphql`
query MyQueryTemasdelMomento{
    
    generico: allContentfulGenerico(
        sort: { fields: createdAt, order: DESC }
      ) {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
                nombre
            }
          }
        }
      }

      

  }
`;
