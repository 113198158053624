import React, {useLayoutEffect, useState}  from 'react';
import { Link } from 'gatsby';
import { SvgIcon } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Menu from '@material-ui/icons/Menu';
import {IconButton, Drawer, List, ListItem, ListItemText, Divider} from '@material-ui/core';
import '../theme/header.scss';

const HeaderNew = (): JSX.Element => {
    let color = "primary"
    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();

            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }
    const [width, height] = useWindowSize();
    const [menu, setMenu] = useState(false);
    const [input, setInput] = useState(false);

    const links = [
        {
            label: 'Comentario diario',
            link: '/comentario-diario-historico',
        },
        {
            label: 'Mercados',
            link: '/mercados',
        },
        {
            label: 'Internacional',
            link: '/internacional',
        },
        {
            label: 'Renta Variable',
            link: '/renta-variable',
        },
        {
            label: 'Técnico',
            link: '/tecnico',
        },
        {
            label: 'Económico',
            link: '/economico',
        },
        {
            label: 'Reportes Trimestrales',
            link: '/reportes-trimestrales',
        },
        {
            label: 'Basics',
            link: '/vector-basics',
        }
    ];

    return(
        <div style={{padding: "0 2rem"}}>
            <div className='header'>
                <div className='header-logo'>
                    <Link to="/">
                        <SvgIcon
                            viewBox="0 0 548 90"
                            style={{ width:"auto" }}
                            className="logoSVG"
                        >
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#FF5000'}
                            d="M547,1.2H445c6,2.3,14.2,6.1,22.4,12l59,0.2l-29.1,48.9c0.9,7.2,1,15.1-0.1,23.9L547,1.2z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#FF5000'}
                            d="M513.4,20.7h-37c7.7,7.5,14.6,17.3,18.4,30.1L513.4,20.7z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#002C55'}
                            d="M0,35.1h10l15.4,39.3l15.3-39.3h9.7L29.2,86.7h-8L0,35.1z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#002C55'}
                            d="M67.2,87.2c-2.8,0-5.4-0.5-7.8-1.4c-2.4-1-4.5-2.3-6.3-4.1c-1.8-1.8-3.2-3.9-4.2-6.3c-1-2.5-1.5-5.2-1.5-8.2
                v-0.1c0-2.8,0.5-5.4,1.4-7.8s2.3-4.6,3.9-6.4c1.7-1.8,3.7-3.2,6-4.3c2.3-1,4.8-1.6,7.6-1.6c3.1,0,5.8,0.5,8.1,1.7
                c2.3,1.1,4.2,2.6,5.8,4.6c1.5,1.9,2.7,4.1,3.5,6.6c0.8,2.5,1.2,5.1,1.2,7.9c0,0.4,0,0.8,0,1.2s0,0.9-0.1,1.3H56.2
                c0.5,3.2,1.8,5.6,3.8,7.3c2,1.7,4.5,2.5,7.3,2.5c2.2,0,4.1-0.4,5.7-1.2c1.6-0.8,3.2-1.9,4.8-3.4l5.2,4.6c-1.8,2.2-4.1,4-6.6,5.3
                C73.8,86.5,70.7,87.2,67.2,87.2z M75.9,64.3c-0.1-1.4-0.5-2.7-0.9-4c-0.5-1.3-1.1-2.3-2-3.2c-0.8-0.9-1.8-1.6-2.9-2.2
                c-1.1-0.5-2.4-0.8-3.9-0.8c-2.7,0-5,0.9-6.8,2.8c-1.8,1.9-2.8,4.3-3.2,7.3L75.9,64.3L75.9,64.3z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#002C55'}
                            d="M107.8,87.2c-2.9,0-5.5-0.5-8-1.6c-2.4-1-4.6-2.5-6.3-4.3c-1.8-1.8-3.2-3.9-4.2-6.4c-1-2.4-1.5-5-1.5-7.7v-0.1
                c0-2.7,0.5-5.3,1.5-7.8s2.4-4.6,4.2-6.4c1.8-1.8,3.9-3.3,6.4-4.4c2.4-1.1,5.1-1.6,8.1-1.6c1.8,0,3.5,0.2,5,0.5s2.8,0.8,4.1,1.4
                c1.3,0.6,2.4,1.3,3.4,2.1c1,0.8,2,1.7,3,2.7l-5.5,5.9c-1.4-1.4-2.8-2.6-4.4-3.5c-1.5-0.9-3.4-1.4-5.6-1.4c-1.6,0-3.1,0.3-4.5,1
                s-2.6,1.5-3.6,2.6c-1,1.1-1.8,2.4-2.3,3.9s-0.9,3.1-0.9,4.8V67c0,1.7,0.3,3.3,0.9,4.8c0.5,1.5,1.4,2.8,2.4,4c1,1.1,2.3,2,3.6,2.6
                c1.4,0.6,3,0.9,4.7,0.9c2.1,0,3.9-0.5,5.5-1.3c1.6-0.9,3.1-2,4.5-3.4l5.3,5.3c-1.9,2.2-4.1,3.9-6.5,5.2
                C114.6,86.5,111.6,87.2,107.8,87.2z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#002C55'}
                            d="M141,87c-1.6,0-3.1-0.2-4.5-0.6s-2.6-1-3.6-1.9s-1.8-2-2.3-3.5c-0.5-1.4-0.8-3.2-0.8-5.4V55.3h-4.9v-7.6h4.9
                V37.1h8.8v10.6H149v7.6h-10.4v18.9c0,1.7,0.4,2.9,1.3,3.7c0.8,0.8,2,1.1,3.5,1.1c1.9,0,3.7-0.5,5.5-1.3V85
                c-1.1,0.6-2.3,1.1-3.6,1.4C144.1,86.8,142.7,87,141,87z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#002C55'}
                            d="M171.7,87.2c-2.9,0-5.6-0.5-8.2-1.6c-2.5-1-4.7-2.5-6.5-4.3c-1.8-1.8-3.2-3.9-4.3-6.4c-1-2.4-1.5-5-1.5-7.7
                v-0.1c0-2.8,0.5-5.4,1.6-7.8c1-2.4,2.5-4.6,4.3-6.4s4-3.3,6.5-4.4c2.5-1.1,5.3-1.6,8.2-1.6s5.7,0.5,8.2,1.6c2.5,1,4.7,2.5,6.5,4.3
                s3.2,4,4.3,6.4c1,2.4,1.5,5,1.5,7.8v0.1c0,2.7-0.5,5.3-1.6,7.7s-2.5,4.6-4.3,6.4s-4,3.3-6.5,4.4S174.7,87.2,171.7,87.2z
                    M171.8,79.5c1.8,0,3.4-0.3,4.9-1c1.4-0.7,2.7-1.5,3.7-2.6s1.8-2.4,2.3-3.9s0.8-3.1,0.8-4.7v-0.1c0-1.7-0.3-3.3-0.9-4.8
                c-0.6-1.5-1.4-2.8-2.4-4c-1-1.1-2.3-2-3.7-2.7s-3-1-4.8-1s-3.3,0.3-4.8,1c-1.4,0.7-2.7,1.5-3.7,2.6c-1,1.1-1.8,2.4-2.3,3.9
                s-0.8,3.1-0.8,4.8v0.1c0,1.7,0.3,3.3,0.9,4.8c0.6,1.5,1.4,2.8,2.4,3.9c1,1.1,2.3,2,3.7,2.7C168.5,79.1,170.1,79.5,171.8,79.5z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#002C55'}
                            d="M197.7,47.7h8.8v8.7c1.2-2.9,2.9-5.3,5.2-7s5.1-2.6,8.5-2.4v9.4h-0.5c-1.9,0-3.7,0.3-5.3,0.9
                c-1.6,0.6-3,1.6-4.2,2.8c-1.2,1.3-2.1,2.9-2.7,4.8c-0.6,1.9-0.9,4.1-0.9,6.7v14.7h-8.8V47.7H197.7z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#FF5000'}
                            d="M244.9,34.8h5.4l23.3,51.5h-6.2l-6-13.5h-27.8l-6.1,13.5h-5.9L244.9,34.8z M259.2,67.5l-11.6-26l-11.7,26
                H259.2z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#FF5000'}
                            d="M279.1,48.5h5.6v6.6c1.3-2,2.9-3.8,5-5.2c2-1.4,4.7-2.2,8-2.2c2.3,0,4.3,0.4,6.1,1.1c1.8,0.7,3.3,1.8,4.6,3.1
                c1.3,1.4,2.2,2.9,2.8,4.8c0.7,1.8,1,3.9,1,6.1v23.5h-5.6V64.1c0-3.5-0.9-6.3-2.7-8.3c-1.8-2.1-4.4-3.1-7.7-3.1
                c-1.6,0-3.1,0.3-4.5,0.9c-1.4,0.5-2.6,1.4-3.6,2.4s-1.8,2.3-2.4,3.8c-0.5,1.5-0.9,3.1-0.9,4.8v21.7h-5.6L279.1,48.5L279.1,48.5z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#FF5000'}
                            d="M331.8,87.1c-1.8,0-3.5-0.2-5.1-0.7c-1.7-0.5-3.2-1.2-4.6-2.3c-1.4-1-2.4-2.3-3.2-3.7
                c-0.8-1.4-1.2-3.2-1.2-5.1v-0.1c0-2,0.4-3.7,1.2-5.3c0.8-1.5,1.9-2.8,3.3-3.8s3.2-1.8,5.1-2.3c2-0.5,4.1-0.8,6.5-0.8
                s4.6,0.1,6.4,0.5c1.8,0.3,3.6,0.7,5.3,1.2v-1.3c0-3.4-1-5.9-3-7.7c-2-1.7-4.7-2.6-8.3-2.6c-2.3,0-4.3,0.3-6.1,0.8
                c-1.8,0.5-3.6,1.2-5.4,2l-1.7-4.6c2.1-1,4.2-1.8,6.4-2.3c2.2-0.6,4.6-0.9,7.4-0.9c5.4,0,9.4,1.4,12.2,4.2c2.6,2.6,3.9,6.3,3.9,11
                v23.1h-5.4v-5.7c-1.3,1.7-3.1,3.2-5.3,4.6C337.9,86.5,335.2,87.1,331.8,87.1z M332.9,82.6c1.8,0,3.4-0.3,4.9-0.8s2.9-1.2,4-2.1
                s2-2,2.7-3.2c0.7-1.3,1-2.6,1-4.1v-3.6c-1.4-0.4-3-0.8-4.9-1.1c-1.8-0.4-3.9-0.5-6.2-0.5c-3.6,0-6.3,0.7-8.2,2.1
                c-1.9,1.4-2.8,3.3-2.8,5.7v0.1c0,1.2,0.3,2.3,0.8,3.2s1.2,1.8,2.1,2.4c0.9,0.6,1.9,1.1,3,1.4C330.4,82.5,331.6,82.6,332.9,82.6z
                    M340,32.2l5.9,2.7l-8.3,7.9h-4.4L340,32.2z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#FF5000'}
                            d="M359.7,33h5.6v53.4h-5.6V33z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#FF5000'}
                            d="M374.9,34h6.4v6.2h-6.4V34z M375.3,48.5h5.6v37.8h-5.6V48.5z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#FF5000'}
                            d="M402.9,87.1c-2.7,0-5.5-0.5-8.2-1.5c-2.8-1-5.2-2.3-7.4-4.1l2.8-4c2,1.6,4.2,2.7,6.4,3.6
                c2.2,0.8,4.5,1.3,6.7,1.3c2.3,0,4.2-0.5,5.6-1.6s2.2-2.5,2.2-4.4v-0.2c0-0.9-0.3-1.7-0.8-2.4c-0.5-0.7-1.2-1.3-2.1-1.8
                s-1.9-0.9-3-1.4c-1.1-0.4-2.3-0.8-3.6-1.1c-1.4-0.5-2.9-0.9-4.4-1.4s-2.8-1.2-4-1.9c-1.2-0.8-2.2-1.8-2.9-2.9
                c-0.7-1.1-1.1-2.6-1.1-4.4v-0.1c0-1.6,0.3-3.1,0.9-4.4c0.6-1.4,1.5-2.5,2.7-3.4c1.1-0.9,2.5-1.7,4.1-2.2s3.3-0.8,5.1-0.8
                c2.3,0,4.7,0.4,7.1,1.1c2.4,0.8,4.6,1.8,6.5,3l-2.6,4.2c-1.8-1.1-3.6-2-5.5-2.7s-3.8-1-5.7-1c-2.3,0-4,0.5-5.3,1.6
                c-1.3,1-2,2.3-2,3.9v0.1c0,0.9,0.3,1.7,0.8,2.3c0.5,0.7,1.3,1.2,2.2,1.7c0.9,0.5,1.9,0.9,3.1,1.3c1.2,0.4,2.4,0.8,3.6,1.2
                c1.4,0.5,2.9,0.9,4.4,1.4c1.4,0.5,2.7,1.2,3.9,2c1.2,0.8,2.1,1.8,2.8,3c0.7,1.2,1.1,2.6,1.1,4.3v0.1c0,1.8-0.4,3.4-1.1,4.8
                s-1.7,2.6-2.8,3.6c-1.2,1-2.6,1.7-4.2,2.3C406.5,86.8,404.8,87.1,402.9,87.1z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#FF5000'}
                            d="M423.2,34h6.4v6.2h-6.4V34z M423.6,48.5h5.6v37.8h-5.6V48.5z"
                        />
                        <path
                            className="bgVector"
                            fill={color === 'transparent' ? '#fff' : '#FF5000'}
                            d="M451.2,87.1c-2.7,0-5.5-0.5-8.2-1.5c-2.8-1-5.2-2.3-7.4-4.1l2.8-4c2,1.6,4.2,2.7,6.4,3.6
                c2.2,0.8,4.5,1.3,6.7,1.3c2.3,0,4.2-0.5,5.6-1.6s2.2-2.5,2.2-4.4v-0.2c0-0.9-0.3-1.7-0.8-2.4c-0.5-0.7-1.2-1.3-2.1-1.8
                s-1.9-0.9-3-1.4c-1.1-0.4-2.3-0.8-3.6-1.1c-1.4-0.5-2.9-0.9-4.4-1.4s-2.8-1.2-4-1.9c-1.2-0.8-2.2-1.8-2.9-2.9
                c-0.7-1.1-1.1-2.6-1.1-4.4v-0.1c0-1.6,0.3-3.1,0.9-4.4c0.6-1.4,1.5-2.5,2.7-3.4c1.1-0.9,2.5-1.7,4.1-2.2s3.3-0.8,5.1-0.8
                c2.3,0,4.7,0.4,7.1,1.1c2.4,0.8,4.6,1.8,6.5,3l-2.6,4.2c-1.8-1.1-3.6-2-5.5-2.7s-3.8-1-5.7-1c-2.3,0-4,0.5-5.3,1.6
                c-1.3,1-2,2.3-2,3.9v0.1c0,0.9,0.3,1.7,0.8,2.3c0.5,0.7,1.3,1.2,2.2,1.7c0.9,0.5,1.9,0.9,3.1,1.3c1.2,0.4,2.4,0.8,3.6,1.2
                c1.4,0.5,2.9,0.9,4.4,1.4c1.4,0.5,2.7,1.2,3.9,2c1.2,0.8,2.1,1.8,2.8,3c0.7,1.2,1.1,2.6,1.1,4.3v0.1c0,1.8-0.4,3.4-1.1,4.8
                s-1.7,2.6-2.8,3.6c-1.2,1-2.6,1.7-4.2,2.3C454.9,86.8,453.1,87.1,451.2,87.1z"
                        />
                        </SvgIcon>
                    </Link>
                </div>
                <div className='header-search'>
                    <form action="/buscar" className='form-search'>
                        <SearchIcon onClick={(e)=>setInput(!input)}style={{width:"40px", height:"100%", cursor:"pointer"}} className="btn-search"/>
                        <input name="q" className={(input ? "active ":"")+'input-buscar'} type="text" placeholder='Buscar'/>
                    </form>
                    <IconButton onClick={(e)=>setMenu(!menu)} className="btn-menu-mobile">
                        <Menu style={{width:"40px", height:"100%", cursor:"pointer"}}/>
                    </IconButton>
                </div>
                {width <= 1024 &&
                <Drawer
                    anchor={"right"}
                    open={menu}
                    className="menu-mobile"
                >
                    <div className='btn-close-menu'>
                        <IconButton onClick={(e)=>setMenu(!menu)}>
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {links.map((node,index ) => (
                            <Link key={index} to={node.link} >
                                <ListItem button key={node.label}>
                                    <ListItemText primary={node.label} />
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                </Drawer>
                }
            </div>
            <div className='header-menu'>
                <ul>
                    <li>
                        <Link to="/comentario-diario-historico" className='header-menu-link'>COMENTARIO DIARIO</Link>
                    </li>
                    <li>
                        <Link to="/mercados" className='header-menu-link'>MERCADOS</Link>
                    </li>
                    <li>
                        <Link to="/internacional" className='header-menu-link'>INTERNACIONAL</Link>
                    </li>
                    <li>
                        <Link to="/renta-variable" className='header-menu-link'>RENTA VARIABLE</Link>
                    </li>
                    <li>
                        <Link to="/tecnico" className='header-menu-link'>TÉCNICO</Link>
                    </li>
                    <li>
                        <Link to="/economico" className='header-menu-link'>ECONÓMICO</Link>
                    </li>
                    <li>
                        <Link to="/reportes-trimestrales" className='header-menu-link'>REPORTES TRIMESTRALES</Link>
                    </li>
                    <li>
                        <Link to="/vector-basics" className='header-menu-link'>BASICS</Link>
                    </li>
                </ul>
            </div>   
        </div>
    )
}

export default HeaderNew;